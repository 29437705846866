/*global jQuery:false */
$(document).ready(function($) {
    "use strict";

    //Contact
    $('form.contactForm').submit(function(){
        var str;
        var form = $(this);
        var msgField = $(".sendmessage");
        var f = form.find('.field'),
            ferror = false,
            emailExp = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

        f.children('input').each(function(){ // run all inputs
            var exp;
            var i = $(this); // current input
            var rule = i.attr('data-rule');

            if( rule !== undefined ){
                var ierror=false; // error flag for current input
                var pos = rule.indexOf( ':', 0 );
                if( pos >= 0 ){
                    exp = rule.substr( pos+1, rule.length );
                    rule = rule.substr(0, pos);
                }else{
                    rule = rule.substr( pos+1, rule.length);
                }

                switch( rule ){
                    case 'required':
                        if( i.val()==='' ){ ferror=ierror=true; }
                        break;

                    case 'maxlen':
                        if( i.val().length<parseInt(exp) ){ ferror=ierror=true; }
                        break;

                    case 'email':
                        if( !emailExp.test(i.val()) ){ ferror=ierror=true; }
                        break;

                    case 'checked':
                        if( !i.attr('checked') ){ ferror=ierror=true; }
                        break;

                    case 'regexp':
                        exp = new RegExp(exp);
                        if( !exp.test(i.val()) ){ ferror=ierror=true; }
                        break;
                }
                i.next('.validation').html( ( ierror ? (i.attr('data-msg') !== undefined ? i.attr('data-msg') : 'wrong Input') : '' ) ).show('blind');
            }
        });

        f.children('textarea').each(function(){ // run all inputs
            var exp;
            var i = $(this); // current input
            var rule = i.attr('data-rule');

            if( rule !== undefined ){
                var ierror=false; // error flag for current input
                var pos = rule.indexOf( ':', 0 );
                if( pos >= 0 ){
                    exp = rule.substr( pos+1, rule.length );
                    rule = rule.substr(0, pos);
                }else{
                    rule = rule.substr( pos+1, rule.length );
                }

                switch( rule ){
                    case 'required':
                        if( i.val()==='' ){ ferror=ierror=true; }
                        break;

                    case 'maxlen':
                        if( i.val().length<parseInt(exp) ){ ferror=ierror=true; }
                        break;
                }
                i.next('.validation').html( ( ierror ? (i.attr('data-msg') !== undefined ? i.attr('data-msg') : 'wrong Input') : '' ) ).show('blind');
            }
        });

        if( ferror )
            return false;
        else
            str = $(this).serialize();

        $.ajax({
            type: "POST",
            url: "./proxy_email.php",
            data: str,

            success: function(result){
                result = $.parseJSON(result);

                form.fadeOut(500, function(){
                    f.children('input[type="text"],textarea').val('');

                    if(result.status) {
                        msgField.removeClass('error');
                        msgField.fadeIn(500);
                    }

                    else {
                        msgField.data('orgMsg', msgField.html());
                        msgField.html('Während des Versendens ist ein Fehler aufgetreten.<br>Bitte versuchen Sie es nocheinmal.');
                        msgField.addClass('error');
                        msgField.fadeIn(500);
                    }

                    msgField.delay(5000).fadeOut(500, function() {
                        form.fadeIn(500);
                        msgField.html(msgField.data('orgMsg'));
                    });
                });
            },

            error: function(a,b,c) {
                msgField.html('Unknow Error! Please try again!');
                msgField.addClass('error');
                msgField.fadeIn(500);

                msgField.delay(3000).fadeOut(500, function() {
                    form.fadeIn(500);
                    msgField.html(msgField.data('orgMsg'));
                });
            }
        });

        return false;
    });
});
