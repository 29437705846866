var myvalidateJs = require('./validate.js');

/**
 * preload
 */
$(window).load(function () {
	'use strict';

	$('#status').fadeOut();
	$('#preloader').delay(350).fadeOut('slow');
});

/**
 * after preload
 */
$(document).ready(function($) {
	'use strict';

	/* scroll top */
	$('.scrollup').click(function(){
		$(window).scrollTo(0, 1000);
		return false;
	});

	var blockScroll = false;
	var $curItem;

	/* navigation */
	$('.scroll').click(function() {
		blockScroll = true;
		$(window).scrollTo($(this).attr('href'), 1000, {
			offset: -80,
			onAfter: function() {
				blockScroll = false;
			}
		});
	});

	/* navigation */
	$('#menu-main li').each(function() {
		var activeCls = 'active';
		var $liItem = $(this);
		var $liItems = $liItem.siblings('li').andSelf();
		var $link = $liItem.find('a');
		var $section = $($link.attr('href'));

		// handle click event
		$link.click(function( event ) {
			if( $link.is('.external'))
				return false;

			$liItems.removeClass( activeCls );
			$liItem.addClass( activeCls );

			if( $link.is('[data-reveal-id]')) {
				event.preventDefault();
				blockScroll = true;
				$(window).scrollTo(0, 0);

				var modal = $('#' + $link.attr('data-reveal-id')).reveal({
					animationSpeed: 200
				});

				modal.off('reveal:closed');
				modal.on('reveal:closed', function() {
					blockScroll = false;
					$(window).scrollTo(0, 0, {
						onAfter: function() {
							$liItems.removeClass( activeCls );
							$liItems.eq(0).addClass( activeCls );
						}
					});
				});
			}

		});

		// single page navigation
		if(!$link.is('.external, [data-reveal-id]')) {
			$section.waypoint( function(direction) {
				$curItem = (direction == 'down') ? $liItem : $liItem.prev('li');

				if(blockScroll)
					return false;

				$liItems.removeClass( activeCls );
				$curItem.addClass( activeCls );
			}, {
				offset: '100px'
			});
		}

	});

	//
	$(document).on('click','.navbar-collapse.in',function(e) {
		if( $(e.target).is('a') && $(e.target).attr('class') != 'dropdown-toggle' ) {
	    $(this).collapse('hide');
		}
	});

	/* form palceholder */
	$('input, textarea').placeholder();

	/* gallery slider */
	$('.master-slider').each(function() {
		var slider = new MasterSlider();
		slider.setup($(this), {
			loop: true,
			autoplay: true,
			speed: 20,
			width: 640,
			height: 350,
			space: 0,
			preload: 3,
			layout: 'partialview'
		});
	});

	/* background slider */
	$('body').vegas({
		preload: true,
		overlay: '/img/overlay.png',
		transitionDuration: 1000,
		delay: 4000,
		slides: [
			{ src: 'img/background/BURGER-AG_Slider_1.jpg' },
			{ src: 'img/background/BURGER-AG_Slider_2.jpg' },
			{ src: 'img/background/BURGER-AG_Slider_3.jpg' },
			{ src: 'img/background/BURGER-AG_Slider_4.jpg' },
			{ src: 'img/background/BURGER-AG_Slider_5.jpg' }
		]
	});

	/* header text slider */
	$('#main-flexslider').flexslider({
		animation: 'swing',
		direction: 'vertical',
		slideshow: true,
		slideshowSpeed: 4000,
		animationDuration: 1000,
		directionNav: false,
		prevText: '<i class="fa fa-angle-up icon-2x"></i>',
		nextText: '<i class="fa fa-angle-down icon-2x active"></i>',
		controlNav: false,
		smootheHeight:true,
		useCSS: false
	});

	/* testimonial carousel */
	jQuery('.testimonial-slider').owlCarousel({
		lazyLoad : true,
		slideSpeed : 300,
		stopOnHover: true,
		paginationSpeed : 400,
		autoPlay: 3500,
		navigation : false,
		pagination : false,
		autoHeight : true,
		navigationText : ['',''],
		items : 1, //10 items above 1000px browser width
		itemsDesktop : [1000,1], //5 items between 1000px and 901px
		itemsDesktopSmall : [900,1], // betweem 900px and 601px
		itemsTablet: [600,1], //2 items between 600 and 0
		itemsMobile : [420,1]
	});

	/* toggle maps overlay */
	var overlay = $('div.overlay'),
			overlayTriggerBtn = $('.trigger-overlay'),
			overlayCloseBtn = $('.overlay-close');

	overlayTriggerBtn.click(function() {
				if($('html').hasClass('lt-ie9')) {
						if(overlay.css('visibility') == 'hidden')
								overlay.css({opacity: 0, visibility: 'visible'}).animate({ opacity: 1 }, 300);
						else {
								overlay.css({opacity: 1, visibility: 'visible'}).animate({opacity: 0}, 300, function() {
										overlay.css({ visibility: 'hidden' });
								});
						}
				}

				else
		overlay.toggleClass('open');
	});

		overlayCloseBtn.click(function() {
				if($('html').hasClass('lt-ie9')) {
						overlay.css({opacity: 1, visibility: 'visible'}).animate({opacity: 0}, 300, function() {
								overlay.css({ visibility: 'hidden' });
						});
				}

				else
						overlay.removeClass('open');
		});

	/* google maps */
	var posLatitude = $('#map').data('position-latitude'),
			posLongitude = $('#map').data('position-longitude'),
			latlng = new google.maps.LatLng(posLatitude,posLongitude);

	var mapstyles = [ {
				'featureType': 'road',
				'stylers': [
						{
								'hue': '#5e00ff'
						},
						{
								'saturation': -79
						}
				]
		},
		{
				'featureType': 'poi',
				'stylers': [
						{
								'saturation': -78
						},
						{
								'hue': '#6600ff'
						},
						{
								'lightness': -47
						},
						{
								'visibility': 'off'
						}
				]
		},
		{
				'featureType': 'road.local',
				'stylers': [
						{
								'lightness': 22
						}
				]
		},
		{
				'featureType': 'landscape',
				'stylers': [
						{
								'hue': '#6600ff'
						},
						{
								'saturation': -11
						}
				]
		},
		{},
		{},
		{
				"featureType": "water",
				"stylers": [
						{
								"saturation": -65
						},
						{
								"hue": "#1900ff"
						},
						{
								"lightness": 8
						}
				]
		},
		{
				"featureType": "road.local",
				"stylers": [
						{
								"weight": 1.3
						},
						{
								"lightness": 30
						}
				]
		},
		{
				"featureType": "transit",
				"stylers": [
						{
								"visibility": "simplified"
						},
						{
								"hue": "#5e00ff"
						},
						{
								"saturation": -16
						}
				]
		},
		{
				"featureType": "transit.line",
				"stylers": [
						{
								"saturation": -72
						}
				]
		}

	];

	var options = {
			zoom: 11,
			center: latlng,
			disableDefaultUI: true,
			mapTypeControlOptions: { mapTypeIds: ['Styled'] },
			mapTypeId: 'Styled',
			mapTypeControl: false,
			streetViewControl: false,
			panControl: true,
			zoomControl: true,
			zoomControlOptions: {
					style: google.maps.ZoomControlStyle.SMALL,
			},
			draggable: false,
			scrollwheel: false
	};

	var map = new google.maps.Map(document.getElementById('map'), options),
			styledMapType = new google.maps.StyledMapType(mapstyles, { name: 'Styled' }),
			markerImage = $('#map').data('marker-img'),
			markerWidth = $('#map').data('marker-width'),
			markerHeight = $('#map').data('marker-height');

	map.mapTypes.set('Styled', styledMapType);

	var image = new google.maps.MarkerImage(markerImage,
							new google.maps.Size(markerWidth, markerHeight),
							new google.maps.Point(0,0),
							new google.maps.Point(18, 42));

	var locations = [
		['Burger AG, Frankfurt Nieder-Eschbach', 50.19066, 8.6664, 1],
		['Burger AG, Trebur/Astheim', 49.93517, 8.37611, 2],
		['Burger AG, Wiesbaden', 50.0724825, 8.2483534, 3]
	];

	var infowindow = new google.maps.InfoWindow();
	var marker, i;

	for (i = 0; i < locations.length; i++) {
		marker = new google.maps.Marker({
			position: new google.maps.LatLng(locations[i][1], locations[i][2]),
			map: map,
			icon: image
		});

		google.maps.event.addListener(marker, 'click', (function(marker, i) {
			return function() {
				infowindow.setContent(locations[i][0]);
				infowindow.open(map, marker);
			};
		})(marker, i));
	}

});
